<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel panel-primary">
				    <div class="panel-body">
				        <h3 class="panel-title">
				        	<inline-svg :src="navbarInfo.icon" class="panel-icon" alt="" /> {{ navbarInfo.name }}
				        </h3>
				        <form action="#" @submit.prevent="profile">
				        	<div class="form-group">
				        		<label>Tài khoản :</label>
				        		<input type="text" class="form-control" :value="user.username" readonly="" required="">
				        	</div>
				        	<div class="form-group">
						        <label>Mật khẩu cũ :</label>
						        <input type="password" class="form-control" v-model="form.password" placeholder="Nhập mật khẩu cũ" required="">
						    </div>
						    <div class="form-group">
						        <label>Mật khẩu mới :</label>
						        <input type="password" class="form-control" v-model="form.new_password" placeholder="Nhập mật khẩu mới" required="">
						    </div>
						    <div class="form-group">
						        <label>Nhập lại mật khẩu mới :</label>
						        <input type="password" class="form-control" v-model="form.confirm_password" placeholder="Nhập lại mật khẩu mới" required="">
						    </div>
				        	<button class="btn btn-info b-info btn-block">Cập nhật thông tin</button>
				        </form>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>
	export default {
		data () {
			return {
				form: {}
			}
		},
		props: ['user', 'navbarInfo'],
		methods: {
			profile () {
				this.$http.post('profile?action=change_password', this.form).then(res => {
					if (res.body.status == 1) {
						this.form = {};
					}
	        		this.$swal('Thông báo', res.body.message, (res.body.status == 0 ? 'error' : 'success'));
				});
			}
		}
	}
</script>